import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MSAL_INSTANCE, MsalBroadcastService, MsalModule, MsalService } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AuthInterceptorService } from './core/interceptor/authorization.interceptor';
import { AppRoutingModule } from './pages/app-routing.module';

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: environment.msalConfig.auth.clientId,
			authority: environment.msalConfig.auth.authority,
			redirectUri: window.location.origin
		},
		cache: {
			cacheLocation: 'localStorage',
			storeAuthStateInCookie: true
		}
	});
}

@NgModule({
	declarations: [AppComponent],
	imports: [BrowserModule, AppRoutingModule, NgbModule, BrowserAnimationsModule, MsalModule, HttpClientModule],
	providers: [
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptorService,
			multi: true
		},
		MsalService,
		MsalBroadcastService
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
