import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthLocalService } from '../services/auth/local/auth-local.service';

@Injectable({
	providedIn: 'root'
})
class PermissionsService {
	constructor(
		private _router: Router,
		private authLocalService: AuthLocalService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (this.authLocalService.isLogued()) {
			const isNewPassword = this.authLocalService.isNewPassword();
			if (isNewPassword === true) {
				this._router.navigate(['/contrasena']);
				return false;
			}

			if (isNewPassword === null) {
				this._router.navigate(['/autenticar']);
				return false;
			}
			return true;
		} else {
			this.authLocalService.setRedirectUrl(state.url);
			this._router.navigate(['/autenticar']);
			return false;
		}
	}
}

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
	return inject(PermissionsService).canActivate(route, state);
};
