import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, UrlTree } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class RouteRedirectInterceptor {
	constructor(private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
		const url = state.url;
		if (url.endsWith('/oportunidades')) {
			return this.router.parseUrl('/oportunidades/oportunidades/listar');
		}
    if (url.endsWith('/actividades')) {
			return this.router.parseUrl('/actividades/actividades/listar');

    }
    if (url.endsWith('/productos')) {
			return this.router.parseUrl('/productos/productos/listar');
    }
    if (url.endsWith('/productos')) {
			return this.router.parseUrl('/productos/productos/listar');
    }
    if (url.endsWith('/configuracion')) {
			return this.router.parseUrl('/configuracion/usuario/listar');
    }
		return true;
	}
}
