<ng-container>
	<lord-icon
		[ngClass]="classes"
		[attr.src]="'https://cdn.lordicon.com/' + _name + '.json'"
		[trigger]="trigger"
		[scale]="scale"
		[colors]="colors"
		[style.width.px]="size"
		[style.height.px]="size"
	>
	</lord-icon>
</ng-container>
