<button [disabled]="disabled" [ngClass]="classes" (click)="onClicked()" [class.d-white]="disabledWhite">
	<fe-icon
		*ngIf="icon && iconPosition === 'left'"
		[name]="icon"
		[isOutline]="mode === 'secondary' ? false : true"
		[color]="iconColor"
		[size]="iconSize"
		[iconMaterial]="isIconMaterial ? icon : ''"
		[isError]="icon === 'lock' ? true : false"
	></fe-icon>

	<img *ngIf="img && iconPosition === 'left'" [src]="img" />

	<span *ngIf="text">
		{{ text }}
	</span>
	<img *ngIf="img && iconPosition === 'right'" [src]="img" />

	<fe-icon
		*ngIf="icon && iconPosition === 'right'"
		[name]="icon"
		[isOutline]="mode === 'secondary' ? false : true"
		[color]="iconColor"
		[size]="iconSize"
		[iconMaterial]="isIconMaterial ? icon : ''"
	></fe-icon>

	<div *ngIf="loading">
		<fe-animated-icon size="25" trigger="loop" scale="85" name="xjovhxra" [colorPrimary]="colorAnimatedHexa">
		</fe-animated-icon>
	</div>
</button>
