import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalLoaderComponent } from 'src/app/commons/components/atoms/modal-loader/modal-loader.component';

@Injectable({ providedIn: 'root' })
export class LoaderService {
	constructor(private modalService: NgbModal) {}
	showModalLoader(isShow = true) {
		if (isShow) {
			this.modalService.open(ModalLoaderComponent, {
				centered: true,
				backdrop: 'static',
				windowClass: 'modal-loader'
			});
		}
	}

	closeModalLoader(isClose = true) {
		if (isClose) this.modalService.dismissAll();
	}
}
