import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthLocalService } from '../services/auth/local/auth-local.service';
import { LoaderService } from '../services/modals/loader.service';
import { ModalErrorService } from '../services/modals/modal-error.service';

@Injectable({
	providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
	constructor(
		private router: Router,
		private authServiceLocal: AuthLocalService,
		private loaderService: LoaderService,
		private modalErrorService: ModalErrorService
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token: any = this.authServiceLocal.getToken();
		const tokenPassword: any = this.authServiceLocal.getTokenPassword();
		let request = req;

		if (token) {
			request = req.clone({
				setHeaders: {
					authorization: `Bearer ${token}`
				}
			});
		}

		if (tokenPassword && !token) {
			request = req.clone({
				setHeaders: {
					authorization: `Bearer ${tokenPassword}`
				}
			});
		}

		return next.handle(request).pipe(
			catchError((err: HttpErrorResponse) => {
				this.loaderService.closeModalLoader();

				const currentRoute = window.location.pathname;

				if (err.status === 401 && !currentRoute.includes('contrasena') && !currentRoute.includes('autenticar')) {
					this.modalErrorService.openModalSesionExpired();
				}

				if (
					err.status === 401 &&
					!request.url.includes('validate') &&
					(currentRoute.includes('contrasena') || currentRoute.includes('autenticar'))
				) {
					this.modalErrorService.openModalSesionExpired(false);
				}

				if (err.status === 500 || err.status === 0) {
					this.modalErrorService.openModalErrorPage(err.url);
				}
				if (err.status === 403) {
					this.modalErrorService.openModalAuthorizationPage();
				}

				//  if (err.status === 409) {
				// 	this.router.navigateByUrl('/error/404');
				// }

				return throwError(err);
			})
		);
	}
}
