import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalMessageComponent } from 'src/app/commons/components/atoms/modal-message/modal-message.component';
import { AuthLocalService } from '../auth/local/auth-local.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ModalErrorService {
	constructor(
		private modalService: NgbModal,
		private authLocalService: AuthLocalService,
		private router: Router
	) {}

	openModalErrorPage(urlRequest: string) {
		const modalRef = this.modalService.open(ModalMessageComponent, {
			centered: true,
			backdrop: 'static',
			windowClass: 'modal-message'
		});
		modalRef.componentInstance.iconMaterial = 'priority_high';
		modalRef.componentInstance.typeMessage = 'warning';
		modalRef.componentInstance.title = urlRequest.includes('list') ? 'Problemas al cargar' : 'Error en su solicitud';
		modalRef.componentInstance.subTitle = `No se pudo ${
			urlRequest.includes('list') ? 'cargar ' : 'procesar'
		} la información solicitada. <br> Por favor, intente más tarde.`;
		modalRef.componentInstance.textButton = 'Aceptar';
		modalRef.componentInstance.clickButton.subscribe({
			next: () => {
				if (window.location.pathname.includes('contrasena/validate')) {
					this.router.navigateByUrl('/autenticar');
				}
			}
		});
	}
	openModalAuthorizationPage() {
		const modalRef = this.modalService.open(ModalMessageComponent, {
			centered: true,
			backdrop: 'static',
			windowClass: 'modal-message'
		});
		modalRef.componentInstance.iconMaterial = 'priority_high';
		modalRef.componentInstance.typeMessage = 'warning';
		modalRef.componentInstance.title = 'No cuenta con permisos';
		modalRef.componentInstance.subTitle = `No se pudo mostar el contendido solicitado. <br> Por favor, comuníquese con soporte.`;
		modalRef.componentInstance.textButton = 'Aceptar';
		modalRef.componentInstance.clickButton.subscribe({
			next: () => {
				// if (window.location.pathname.includes('contrasena/validate')) {
				// 	this.router.navigateByUrl('/autenticar');
				// }
			}
		});
	}

	openModalSesionExpired(isSesionExpired = true) {
		const modalRef = this.modalService.open(ModalMessageComponent, {
			centered: true,
			backdrop: 'static',
			windowClass: 'modal-message'
		});
		modalRef.componentInstance.iconMaterial = isSesionExpired ? 'priority_high' : 'close';
		modalRef.componentInstance.typeMessage = isSesionExpired ? 'warning' : 'danger';
		modalRef.componentInstance.title = isSesionExpired ? 'La sesión expiró' : 'Usuario no autorizado';
		modalRef.componentInstance.subTitle = isSesionExpired
			? 'Ha sobrepasado el tiempo permitido. <br> Inicie sesión nuevamente'
			: 'Para continuar comuníquese con soporte';
		modalRef.componentInstance.textButton = 'Aceptar';
		modalRef.componentInstance.clickButton.subscribe({
			next: () => {
				this.authLocalService.singOutMSAL();
				this.authLocalService.signOut();
			}
		});
	}
}
