import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { I_Message } from './modal-message.interface';
import { ButtonComponent } from '../button/button.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-modal-message',
	standalone: true,
	imports: [CommonModule, MatIconModule, ButtonComponent],
	templateUrl: './modal-message.component.html',
	styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent implements OnInit {
	@Input() img = '';
	@Input() iconMaterial = 'close';
	@Input() title = '';
	@Input() subTitle = '';
	@Input() typeMessage: I_Message = 'default';
	@Input() textButton = '';
	@Input() textButtonSecondary = '';
	@Input() isClose = false;
	@Input() time = 5000;
	@Input() isCloseAutomatic = false;
	@Output() clickButton = new EventEmitter();
	@Output() clickButtonSecondary = new EventEmitter();

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {
		if (this.textButton == '' || this.isCloseAutomatic) {
			setTimeout(() => {
				this.closeModal();
			}, this.time);
		}
	}

	clickAction() {
		this.activeModal.close();
		this.clickButton.emit();
	}

	clickActionSecondary() {
		this.activeModal.close();
		this.clickButtonSecondary.emit();
	}

	closeModal() {
		this.activeModal.close();
		localStorage.removeItem('isSuccess');
	}
}
