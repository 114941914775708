import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { LoginGuard } from '../core/guards/login.guard';
import { PasswordGuard } from '../core/guards/password.guard';
import { RouteRedirectInterceptor } from '../core/interceptor/url.interceptor';

const routes: Routes = [
	{
    path: '',

		loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
		canActivate: [AuthGuard,RouteRedirectInterceptor]
  },
	{
		path: 'autenticar',
		loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
		canActivate: [LoginGuard]
	},
	{
		path: 'contrasena',
		loadChildren: () => import('./password/password.module').then((m) => m.PasswordModule),
		canActivate: [PasswordGuard]
	},
	{
		path: 'error',
		loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule)
	},
	{
		path: '**',
		loadChildren: () => import('./error/error.module').then((m) => m.ErrorModule)
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
