import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthLocalService } from '../services/auth/local/auth-local.service';

@Injectable({
	providedIn: 'root'
})
class PermissionsService {
	constructor(
		private _router: Router,
		private authLocalService: AuthLocalService
	) {}

	canActivate(): boolean {
		if (this.authLocalService.isLogued()) {
			this._router.navigateByUrl(this.authLocalService.isNewPassword() === true ? '/contrasena' : '/');
			return false;
		}
	}
}

export const LoginGuard: CanActivateFn = (): boolean => {
	return inject(PermissionsService).canActivate();
};
