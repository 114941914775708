<section>
	<div class="close__modal" *ngIf="isClose">
		<img src="./assets/svg/close.svg" alt="x" width="20" height="20" (click)="closeModal()" />
	</div>
	<div class="d-flex flex-column gap-4 justify-content-center align-items-center">
		<div [className]="'icon-modal icon-modal__' + typeMessage">
			<mat-icon *ngIf="img === ''" aria-hidden="false" [fontIcon]="iconMaterial"></mat-icon>
			<img  *ngIf="img !== ''" [src]="img" alt="x" width="20" height="20"  />
		</div>
		<div class="d-flex flex-column gap-2">
			<span class="title-modal" *ngIf="title !== ''">{{ title }}</span>
			<span class="subtitle-modal" *ngIf="subTitle !== ''" [innerHtml]="subTitle"></span>
		</div>
	</div>
	<div
		*ngIf="textButton !== ''"
		class="d-flex w-100"
		[ngClass]="textButtonSecondary !== '' ? 'justify-content-between' : 'justify-content-center'"
	>
		<div class="actions" *ngIf="textButtonSecondary !== ''">
			<fe-button
				mode="secondary"
				[text]="textButtonSecondary"
				type="full"
				(clicked)="clickActionSecondary()"
			></fe-button>
		</div>
		<div class="actions"><fe-button [text]="textButton" type="full" (clicked)="clickAction()"></fe-button></div>
	</div>
</section>
