import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Injectable({ providedIn: 'root' })
export class AuthLocalService {
	constructor(
		private router: Router,
		private msalService: MsalService
	) {}

	isLogued() {
		const user = JSON.parse(localStorage.getItem('dataUser'));
		return user ? true : false;
	}

	isNewPassword() {
		const user = this.getInfoUserLocal();
		return user ? user.passwordChange : null;
	}

	getInfoUserLocal() {
		const user = JSON.parse(localStorage.getItem('dataUser'));
		return user;
	}
	setRedirectUrl(url: string) {
		localStorage.setItem('redirectUrl', url);
	}

	getRedirectUrl(): string | null {
		return localStorage.getItem('redirectUrl');
	}

	clearRedirectUrl() {
		localStorage.removeItem('redirectUrl');
	}
	getToken() {
		const dataUser = JSON.parse(localStorage.getItem('dataUser'));
		return dataUser ? dataUser.token : null;
	}

	getDataPermisions() {
		const dataUser = JSON.parse(localStorage.getItem('dataUser'));
		return dataUser ? dataUser.capabilityPermission.general : null;
	}
	getDataPermisionsValidated() {
		const dataUser = JSON.parse(localStorage.getItem('dataUser'));
		return dataUser ? dataUser?.capabilityPermission?.custome : null;
	}

	getTokenPassword() {
		const token = localStorage.getItem('passwordToken');
		return token ? token : null;
	}

	getNameUser() {
		const user = this.getInfoUserLocal();
		return user ? user.username : null;
	}

	signOut(isRedirect = true) {
		localStorage.removeItem('dataUser');
		localStorage.clear();
		if (isRedirect) this.router.navigateByUrl('/autenticar');
	}

	singOutMSAL() {
		const currentAccount = this.msalService.instance.getActiveAccount();
		if (currentAccount) {
			localStorage.removeItem(`msal.${currentAccount.idTokenClaims.aud}.active-account`);
			localStorage.removeItem(`msal.${currentAccount.idTokenClaims.aud}.active-account-filters`);
			localStorage.removeItem(`msal.token.keys.${currentAccount.idTokenClaims.aud}`);
		}
	}

	updatePasswordRenew() {
		const dataUser = JSON.parse(localStorage.getItem('dataUser'));
		if (dataUser) {
			localStorage.setItem('dataUser', JSON.stringify({ ...dataUser, passwordChange: false }));
		}
	}
}
