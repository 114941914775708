export const environment = {
	production: false,
	msalConfig: {
		auth: {
			clientId: 'ab763202-1bd2-44db-9b34-263da3cb5609',
			authority: 'https://login.microsoftonline.com/44a78b44-3fba-4ad7-b951-6bbdc7b98cdb'
		}
	},
	appVersion: 'v0.0.1',
	urlAudit: 'https://bvs-oportunidades-test-audit.azurewebsites.net',
	urlAuth: 'https://bvs-oportunidades-test-asauth.azurewebsites.net',
	urlAPI: 'https://bvs-oportunidades-test-core.azurewebsites.net',
	keyCaptca: '6LfbX9cpAAAAAE3zdW_kqv_sffUqZhXf7qn991Pp'
};
