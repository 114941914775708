<i [ngClass]="classes" [style.font-size.px]="size" *ngIf="iconMaterial === ''" [class.fe-icon__color-red]="isError"></i>
<mat-icon
	aria-hidden="false"
	[ngClass]="classes"
	*ngIf="iconMaterial !== ''"
	[fontIcon]="iconMaterial"
	[class.fe-icon__color-red]="isError"
	[style.width.px]="size"
	[style.height.px]="size"
	[style.font-size.px]="size"
></mat-icon>
